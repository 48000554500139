<template>
  <div>
    <h1>name : {{ name }}</h1>
    <h1>title : {{ title }}</h1>
    <h1>description : {{ description }}</h1>
    <h1>Date : {{ tDate | momentDateTimeFormat }}</h1>

    <input type="text" v-model="nameProxy">
    <input type="text" v-model="titleProxy">
    <input type="text" v-model="descriptionProxy">

    <button @click="clearName">Clear Name</button>
    <button @click="clearAll">Clear All</button>
    <button @click="clickMe">Click Me</button>

  </div>
</template>

<script>
import {
  CLEAR_ALL_VARS,
  CLEAR_NAME,
  DESCRIPTION, LOADING,
  NAME,
  SET_DESCRIPTION,
  SET_NAME,
  SET_TITLE,
  TITLE
} from "@/core/services/store/test/test.module";
import {mapGetters, mapMutations} from "vuex";
import moment from 'moment'

export default {
  name: "Test",
  data() {
    return {
      tDate: moment(),
    }
  },
  computed: {
    ...mapGetters({
      name: NAME,
      title: TITLE,
      description: DESCRIPTION,
      loading: LOADING
    }),
    nameProxy: {
      set(value){
        this.setName(value);
      },
      get(){
        return this.name;
      }
    },
    titleProxy: {
      set(value){
        this.setTitle(value);
      },
      get(){
        return this.title;
      }
    },
    descriptionProxy: {
      set(value){
        this.setDescription(value);
      },
      get(){
        return this.description;
      }
    },
  },
  methods: {
    ...mapMutations({
      setName: SET_NAME,
      setTitle: SET_TITLE,
      setDescription: SET_DESCRIPTION,
      clearName: CLEAR_NAME,
    }),
    clearAll(){
      this.$store.dispatch(CLEAR_ALL_VARS);
    }
  }

}
</script>

<style scoped>

</style>